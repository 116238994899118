import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CircularProgress, Divider, Fab, Grid, IconButton, InputLabel, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Driverheader from "./Driverheader";
import { useSelector } from "react-redux";
import { Apiurl } from "../api_urls";
import axios from "axios";
import { customDateFormat, GToaster } from "../Components/g_toaster";
import { CalendarMonth } from "@mui/icons-material";
import SearchFilter from "react-filter-search";
import nodata from "./Driverimages/bookingnotyet.png"
import { Call, ExpandMore, Message, MessageOutlined } from "@material-ui/icons";
import wats from "./Driverimages/whatsapp.png"

const toster = new GToaster()
function RiderHome() {
    const navi = useNavigate();
    const shift_type = {
        morning: "morning",
        afternoon: "afternoon",
        evening: "evening",
        night: "night"
    }

    const [loading, setloading] = useState(false);
    const [filterdatamodal, setfilterdatamodal] = useState(false)
    const [shiftvalue, setshiftvalue] = useState(shift_type?.morning)
    let date = new Date()
    const [daterange, setdaterange] = useState({
        startdate: customDateFormat(date, 'YYYY-MM-DD'),
        enddate: customDateFormat(date, 'YYYY-MM-DD')
    })



    const [inp2, setinp2] = useState("")
    const [mydata, setmydata] = useState([]);
    const driver = useSelector((state) => state?.data?.riderdata?.rider);
    let today = new Date();
    today.setDate(today.getDate() + 7)
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let todaydate = yyyy + "-" + mm + "-" + dd;

    const changedata = (mydata) => {
        let myarr = []
        Object.keys(mydata).forEach((date) => {
            let shiftkeys = Object.keys(mydata[date])
            shiftkeys.forEach((shiftdata) => {
                let slotkeys = Object.keys((mydata[date][shiftdata]))
                slotkeys.forEach((slotdata) => {
                    let bookingdata = mydata[date][shiftdata][slotdata]
                    myarr.push({
                        slot_type: slotdata,
                        date_type: date,
                        shift_type: shiftdata,
                        assign_booking: bookingdata
                    })
                })
            })
        })
        return myarr
    }

    const getassignstudent = async () => {
        setloading(true)
        let url = `${Apiurl.RIDER_ASSIGN_BOOKING_FILTER_URL}${driver.id}/${daterange?.startdate}/${daterange?.enddate}`
        await axios.get(url).then((res) => {
            if (res.data.status === 200) {
                setloading(false)
                let newdata = changedata(res?.data?.data)
                setmydata(newdata)
            } else {
                setloading(false)
                setmydata([])
            }
        }).catch((err) => {
            toster.error({ title: "Something went wrong Bookings data fetch" })
        })
    }


    const filterAccShift = (data) => {
        let filterdata = mydata.filter((fil) => fil?.shift_type?.toLowerCase() === data)?.map((ele) => ele?.assign_booking)
        return filterdata.flat(Infinity)
    }


    async function filterdata(e) {
        e.preventDefault();
        await axios.get(`${Apiurl.RIDER_ASSIGN_BOOKING_FILTER_URL}${driver.id}/${daterange?.startdate}/${daterange?.enddate}`).then((response) => {
            if (response.data.status === 200) {
                let newdata = changedata(response?.data?.data)
                setmydata(newdata)
                setfilterdatamodal(false)
            } else if (response.data.status === 300) {
                toster.warning({ title: "There is no assign bookings available on the selected date" })
            } else {
                toster.error({ title: "Server Error" })
            }
        }).catch((Err) => {
            console.log(Err)
        })

    }

    useEffect(() => {
        if (!driver) {
            navi("/rider")
        } else {
            getassignstudent()
        }
    }, [])




    return (
        <>
            <Driverheader />
            <Grid container spacing={2}>

                <Modal open={filterdatamodal} onClose={() => setfilterdatamodal(false)}>
                    <Grid item lg={4} xs={12} sx={{ m: "auto", mt: 25 }}>
                        <Card>
                            <CardContent>
                                <form onSubmit={filterdata}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography sx={{ fontSize: 27, fontWeight: "bold", color: "#117fb7" }} >
                                                Filter Your Bookings
                                            </Typography>
                                            <Button onClick={() => setfilterdatamodal(false)}>x</Button>
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <InputLabel sx={{ pb: 1 }}>Select Start Date</InputLabel>
                                            <TextField fullWidth size="small" required type="date" onChange={(e) => setdaterange({ ...daterange, startdate: e.target.value })} inputProps={{ max: todaydate, min: "2000-01-01" }} name="startdate" />
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <InputLabel sx={{ pb: 1 }}>Select End Date</InputLabel>
                                            <TextField fullWidth size="small" required type="date" onChange={(e) => setdaterange({ ...daterange, enddate: e.target.value })} inputProps={{ max: todaydate, min: "2000-01-01" }} name="enddate" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="secondary" fullWidth>Submit</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Modal>

                <Grid item xs={12}>
                    <Typography sx={{ textAlign: "center", fontSize: 27, fontWeight: "bold", color: "#117fb7", pt: 4 }}>Assigned Bookings</Typography>
                </Grid>



                <Grid item xs={12}>
                    <CardContent>
                        <Grid container spacing={1}>
                            {!!(window.innerWidth <= 768) && <>
                                <Grid item xs={6}>
                                    <Button variant="contained" color="secondary" startIcon={<CalendarMonth />} onClick={() => setfilterdatamodal(true)} >Choose Date</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth size="small" label="Search Here..." value={inp2} onChange={(e) => setinp2(e.target.value)} />
                                </Grid>
                            </>}


                            <Grid item xs={12} mt={2}>
                                <Grid container spacing={1}>
                                    <Grid item lg={1.2} sm={3} xs={3}>
                                        <Button sx={{ fontSize: { xs: "7px", sm: "0.775rem", md: "0.875rem" } }} fullWidth variant={shiftvalue === shift_type?.morning ? "contained" : "outlined"} onClick={() => setshiftvalue(shift_type?.morning)} > {`(${filterAccShift(shift_type?.morning)?.length})`} Morning</Button>
                                    </Grid>
                                    <Grid item lg={1.4} sm={3} xs={3}>
                                        <Button sx={{ fontSize: { xs: "7px", sm: "0.775rem", md: "0.875rem" } }} fullWidth variant={shiftvalue === shift_type?.afternoon ? "contained" : "outlined"} onClick={() => setshiftvalue(shift_type?.afternoon)} > {`(${filterAccShift(shift_type?.afternoon)?.length})`} Afternoon</Button>
                                    </Grid>
                                    <Grid item lg={1.2} sm={3} xs={3}>
                                        <Button sx={{ fontSize: { xs: "7px", sm: "0.775rem", md: "0.875rem" } }} fullWidth variant={shiftvalue === shift_type?.evening ? "contained" : "outlined"} onClick={() => setshiftvalue(shift_type?.evening)} > {`(${filterAccShift(shift_type?.evening)?.length})`} Evening</Button>

                                    </Grid>
                                    <Grid item lg={1.2} sm={3} xs={3}>
                                        <Button sx={{ fontSize: { xs: "7px", sm: "0.775rem", md: "0.875rem" } }} fullWidth variant={shiftvalue === shift_type?.night ? "contained" : "outlined"} onClick={() => setshiftvalue(shift_type?.night)} > {`(${filterAccShift(shift_type?.night)?.length})`} Night</Button>
                                    </Grid>

                                    {!!(window.innerWidth > 768) && <>
                                        <Grid item lg={1.1} md={6}>
                                            <Button variant="contained" color="secondary" startIcon={<CalendarMonth />} onClick={() => setfilterdatamodal(true)} >Choose Date</Button>
                                        </Grid>
                                        <Grid item lg={5.9} md={6} sx={{ textAlign: "right" }}>
                                            <TextField sx={{ width: {lg:"500px",xs:"100%"} }} size="small" label="Search Here..." value={inp2} onChange={(e) => setinp2(e.target.value)} />
                                        </Grid>
                                    </>}
                                </Grid>
                            </Grid>



                        </Grid>
                    </CardContent>
                </Grid>


               

                <Grid item lg={12} md={12} sm={12} xs={12}>

                    {loading ? <center><CircularProgress /></center>
                        :
                        !!mydata.length ?
                            <Grid container spacing={4} sx={{ pl: 2, pr: 2.5 }}>
                                {mydata.filter((fil) => fil?.shift_type?.toLowerCase() === shiftvalue).map((ele, index) => (
                                    <Grid key={index} item lg={4} md={4} sm={6} xs={12}>
                                        <Accordion sx={{ background: "#117fb7", borderRadius: 25, color: "white" }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore style={{ color: "white" }} />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>Slot : {ele?.slot_type}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>Bookings : {ele?.assign_booking?.length}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography>{customDateFormat(ele?.date_type, "D MMM YYYY, dddd")}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {ele?.assign_booking?.map((book, index) => (
                                                    <Card key={index} sx={{ p: 2, mb: 2 }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} >
                                                                <Typography fontSize={17} fontWeight={"bold"}>{book?.pickup_city} To {book?.dropup_city}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography pb={1} fontSize={13.7} variant="body1">Pick-up : <span style={{ color: "#117fb7", fontWeight: "bold" }}>{book?.pickup_location}</span></Typography>
                                                                <Typography fontSize={13.7} variant="body1">Drop-off : <span style={{ color: "#117fb7", fontWeight: "bold" }}>{book?.dropup_location}</span></Typography>
                                                            </Grid>
                                                            <Grid item xs={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={1} >
                                                                <Typography sx={{ textTransform: "capitalize", color: "black", fontWeight: "bold" }} fontSize={15} variant="body1">{ele?.shift_type}</Typography>
                                                                <Typography sx={{ textTransform: "capitalize", color: "red", fontWeight: "bold" }} fontSize={15} variant="body1">{customDateFormat(book?.booked_date, 'MMM D, YYYY')} {book?.booked_time}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sx={{ borderTop: "1px solid grey", pt: 1 }}>
                                                                <Typography pb={1} sx={{ textTransform: "capitalize" }} variant="body2" fontSize={15} color={"black"}><span style={{ fontWeight: "bold" }}>Name :</span> <span style={{ color: "grey" }}>{book?.name}</span> </Typography>
                                                                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <Typography sx={{ textTransform: "capitalize" }} variant="body2" fontSize={15} color={"black"}><span style={{ fontWeight: "bold" }}>Phone :</span> <span style={{ color: "grey" }}>{book?.mobile}</span> </Typography>
                                                                    <div >
                                                                        <a href={`https://api.whatsapp.com/send?phone=${book?.mobile}`} target="_blank">
                                                                            <img style={{ marginRight: 8 }} src={wats} width={"24px"} />
                                                                        </a>
                                                                        <a href={`sms:${book?.mobile}`} target="_blank">
                                                                            <MessageOutlined style={{ marginRight: 6, color: "#117fb7" }} />
                                                                        </a>
                                                                        <a href={`tel:${book?.mobile}`} target="_blank" style={{ color: "black", textDecoration: "none" }}><Call /></a>

                                                                    </div>
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <center>
                                <img src={nodata} />
                            </center>
                    }

                </Grid>
            </Grid >
        </>
    )
}

export default RiderHome;